/**
 * @format
 * @flow
 */

import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  UPDATE_CART_TOTAL,
  UPDATE_COMMENT,
  UPDATE_TYPE,
  UPDATE_CART_PICKUP_DATE,
  UPDATE_RESTO_INFO,
  SET_FLUSH_CART,
  RESET_CART,
  APPLY_DISCOUNT,
  RETURN_CART_FEES,
  SET_CART_TOTAL_CONFIRM,
  APPLY_COUPON,
  PICKED_ORDER_TYPE,
  UPDATE_TABLE,
  UPDATE_QR_ID,
  SET_DISCOUNT_SETTINGS,
} from '../actions/cartAction';
import { CartType } from '../flowObjectType'

const cartObject: CartType = {
  createdAt: null,
  restoId: '',
  restoName: '',
  restoPostal: '',
  restoInformations: {},
  canOrder: false,
  orderType: 0,
  products: [],
  total: 0,
  totalHT: 0,
  totalVat: 0,
  totalConfirm: 0,
  totalProducts: 0,
  subtotal: 0,
  discount: 0,
  comment: '',
  customerNbr: 0,
  table: '',
  flushCart: false,
  pickupTime: null,
  coupon: null,
  pickedOrderType: false,
  qrId: null,
  settings: { deliveryDiscount: null, takeawayDiscount: null }
}

export default function foo(state: Object = { ...cartObject }, action: Object) {
  switch (action.type) {
    case UPDATE_COMMENT:
      return { ...state, comment: action.payload.comment }
    case UPDATE_TYPE: {
      const { total, totalHT, totalVat, totalProducts, subtotal, products } = getCartPrice(state.products, action.payload.orderType, state.discount, state.settings);
      return {
        ...state,
        total,
        totalHT,
        totalVat,
        totalProducts,
        products,
        subtotal,
        orderType: action.payload.orderType
      }
    }
    case ADD_PRODUCT:
      return {
        ...state,
        createdAt: state.createdAt === null ? new Date() : state.createdAt,
        products: [...state.products, action.payload.product],
      }
    case UPDATE_PRODUCT:
      state.products[action.payload.idx] = action.payload.product
      return { ...state, products: state.products }
    case DELETE_PRODUCT:
      state.products.splice(action.payload.idx, 1)
      return { ...state, products: state.products }
    case UPDATE_CART_TOTAL:
      return {
        ...state,
        total: action.payload.cartTotal,
        totalHT: action.payload.cartTotalHT,
        totalVat: action.payload.cartTotalVat,
        totalProducts: action.payload.cartTotalProducts,
        subtotal: action.payload.cartSubtotal,
      }
    case UPDATE_RESTO_INFO:
      return {
        ...state,
        restoId: action.payload.restoId,
        restoName: action.payload.restoName,
        restoPostal: action.payload.restoPostal,
        canOrder: action.payload.canOrder,
        restoInformations: action.payload.restoInformations,
      }
    case SET_FLUSH_CART:
      return {
        ...state,
        flushCart: true,
      }
    case RESET_CART:
      return {
        ...state,
        createdAt: null,
        coupon: null,
        flushCart: false,
        orderType: 0,
        products: [],
        table: '',
        total: 0,
        totalHT: 0,
        totalVat: 0,
        totalProducts: 0,
        totalConfirm: 0,
        pickedOrderType: false
      }
    case UPDATE_CART_PICKUP_DATE:
      return {
        ...state,
        pickupDate: action.payload.pickupDate,
      }
    case RETURN_CART_FEES:
      return {
        ...state,
        discountTotal: action.payload.discountTotal,
        feeTotal: action.payload.feeTotal,
      }
    case APPLY_DISCOUNT: {
      const { total, totalHT, totalVat, totalProducts, products, subtotal } = getCartPrice(state.products, state.orderType, action.payload.discount, state.settings);
      return {
        ...state,
        total,
        totalHT,
        totalVat,
        totalProducts,
        products,
        subtotal,
        discount: action.payload.discount,
      }
    }
    case SET_CART_TOTAL_CONFIRM:
      return {
        ...state,
        totalConfirm: action.payload.totalConfirm,
      }
    case APPLY_COUPON: {
      return {
        ...state,
        coupon: action.payload.coupon
      }
    }
    case PICKED_ORDER_TYPE: {
      return {
        ...state,
        pickedOrderType: true
      }
    }
    case UPDATE_TABLE: {
      return {
        ...state,
        table: action.payload.table
      }
    }
    case UPDATE_QR_ID: {
      return {
        ...state,
        qrId: action.payload.qrId,
      }
    }
    case SET_DISCOUNT_SETTINGS: {
      return {
        ...state,
        settings: action.payload.settings,
      }
    }
    default:
      return state;
  }
}

const getProductPrice = (product: ProductType, quantity: number, orderType: number, discount: number, applyDiscount: boolean): { total: number, totalHT: number, totalVat: number, subtotal: number } => {
  const rubric = product.rubric

  if (typeof product === 'undefined') return { total: 0, totalHT: 0, totalVat: 0 }
  let total = quantity * product.unitPrice
  let totalHT = quantity * product.unitPriceHT

  rubric.forEach((section) => {
    section.data.forEach((rubric) => {
      if (rubric.checked && rubric.unitPrice > 0) {
        if (section.allowSameMultiple) {
          total += quantity * rubric.unitPrice * (rubric.quantity || 1)
          totalHT += quantity * rubric.unitPriceHT * (rubric.quantity || 1)
        }
        else {
          total += quantity * rubric.unitPrice
          totalHT += quantity * rubric.unitPriceHT
        }
      }
    })
  })

  // apply product discount
  if (orderType === 1 && product.takeawayDiscount && product.takeawayDiscount.discount) {
    total = total * (1 - product.takeawayDiscount.discount / 100)
    totalHT = totalHT * (1 - product.takeawayDiscount.discount / 100)
  }
  else if (orderType === 4 && product.deliveryDiscount && product.deliveryDiscount.discount) {
    total = total * (1 - product.deliveryDiscount.discount / 100)
    totalHT = totalHT * (1 - product.deliveryDiscount.discount / 100)
  }
  else if ((orderType === 2 || orderType === 3) && product.tableDiscount && product.tableDiscount.discount) {
    total = total * (1 - product.tableDiscount.discount / 100)
    totalHT = totalHT * (1 - product.tableDiscount.discount / 100)
  }

  let subtotal = total

  // apply cart discount
  if (discount && applyDiscount) {
    if (
      (orderType === 1 && (!product.takeawayDiscount || (product.takeawayDiscount && product.takeawayDiscount.canDiscount))) ||
      (orderType === 4 && (!product.deliveryDiscount || (product.deliveryDiscount && product.deliveryDiscount.canDiscount))) ||
      ((orderType === 2 || orderType === 3) && (!product.tableDiscount || (product.tableDiscount && product.tableDiscount.canDiscount)))
    ) {
      total = total * (1 - discount / 100)
      totalHT = totalHT * (1 - discount / 100)
    }
  }

  return { total, totalHT, totalVat: total - totalHT, subtotal }
}


const getCartSubtotal = (products, orderType) => {
  let cartSubtotalWithoutDiscount = 0
  products.forEach((product) => {
    cartSubtotalWithoutDiscount += product.quantity * product.unitPrice
    product.rubric.forEach((section) => {
      section.data.forEach((rubric) => {
        if (rubric.checked && rubric.unitPrice > 0) {
          if (section.allowSameMultiple) {
            cartSubtotalWithoutDiscount += product.quantity * rubric.unitPrice * (rubric.quantity || 1)
          }
          else {
            cartSubtotalWithoutDiscount += product.quantityquantity * rubric.unitPrice
          }
        }
      })
    })
    if (orderType === 1 && product.takeawayDiscount && product.takeawayDiscount.discount) {
      cartSubtotalWithoutDiscount = cartSubtotalWithoutDiscount * (1 - product.takeawayDiscount.discount / 100)
    }
    else if (orderType === 4 && product.deliveryDiscount && product.deliveryDiscount.discount) {
      cartSubtotalWithoutDiscount = cartSubtotalWithoutDiscount * (1 - product.deliveryDiscount.discount / 100)
    }
    else if ((orderType === 2 || orderType === 3) && product.tableDiscount && product.tableDiscount.discount) {
      cartSubtotalWithoutDiscount = cartSubtotalWithoutDiscount * (1 - product.tableDiscount.discount / 100)
    }
  })
  return cartSubtotalWithoutDiscount
}

const getCartPrice = (products, orderType, discount, settings) => {
  if (products.length === 0) {
    return { total: 0, totalHT: 0, totalVat: 0, totalProducts: 0, subtotal: 0, products: [] }
  }
  let cartTotal = 0
  let cartTotalHT = 0
  let cartTotalVat = 0
  let cartTotalProducts = 0
  let cartSubtotal = 0
  let cartSubtotalWithoutDiscount = getCartSubtotal(products, orderType)
  let applyDiscount = false
  if (orderType === 1 && cartSubtotalWithoutDiscount >= (settings.takeawayDiscount.discountFrom ?? 0)) {
    applyDiscount = true
  } else if (orderType === 4 && cartSubtotalWithoutDiscount >= (settings.deliveryDiscount.discountFrom ?? 0)) {
    applyDiscount = true
  }

  products.forEach((product) => {
    const { total, totalHT, totalVat, subtotal } = getProductPrice(product, product.quantity, orderType, discount, applyDiscount)
    // product.total = total;
    // product.totalHT = totalHT;
    // product.totalVat = totalVat;
    // product.subtotal = subtotal;
    cartTotal += total
    cartTotalHT += totalHT
    cartTotalVat += totalVat
    cartTotalProducts += product.quantity
    cartSubtotal += subtotal
  })
  return { total: cartTotal, totalHT: cartTotalHT, totalVat: cartTotalVat, totalProducts: cartTotalProducts, subtotal: cartSubtotal, products }
}

