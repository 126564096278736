/**
 * @format
 * @flow
 */

import React from 'react';
import '../styles/ProductCartRow.scss'
import { connect } from 'react-redux';
import { updateProduct, updateCartTotal, deleteProduct } from '../actions/cartAction';
class ProductCartRow extends React.Component {

  deleteProduct = async () => {
    await this.props.deleteProduct(this.props.index)
    await this.getCartPrice()
  }

  updateProduct = async (newQuantity) => {
    const { product, index } = this.props
    const { total, totalHT, totalVat, subtotal } = this.getProductPrice(product, newQuantity, false)
    const newProduct = {
      ...product,
      quantity: newQuantity,
      discount: product.discount || null,
      tableDiscount: product.tableDiscount || null,
      takeawayDiscount: product.takeawayDiscount || null,
      deliveryDiscount: product.deliveryDiscount || null,
      vat: product.vat,
      rubric: product.rubric,
      total,
      totalHT,
      totalVat,
      subtotal,
    }

    await this.props.updateProduct(newProduct, index)
    await this.getCartPrice()
  }

  getCartPrice = () => {
    const cart = this.props.cart
    if (cart.products.length === 0) {
      this.props.updateCartTotal(0, 0, 0, 0, 0)
      return
    }
    let cartTotal = 0
    let cartTotalHT = 0
    let cartTotalVat = 0
    let cartTotalProducts = 0
    let cartSubtotal = 0
    let cartSubtotalWithoutDiscount = this.getCartSubtotal(cart.products)
    let applyDiscount = false
    if (this.props.cart.orderType === 1 && cartSubtotalWithoutDiscount >= (this.props.settings.takeawaySettings.discountFrom ?? 0)) {
      applyDiscount = true
    } else if (this.props.cart.orderType === 4 && cartSubtotalWithoutDiscount >= (this.props.settings.deliverySettings.discountFrom ?? 0)) {
      applyDiscount = true
    }

    cart.products.forEach((product) => {
      const { total, totalHT, totalVat, subtotal } = this.getProductPrice(product, product.quantity, true, applyDiscount)
      cartTotal += total
      cartTotalHT += totalHT
      cartTotalVat += totalVat
      cartTotalProducts += product.quantity
      cartSubtotal += subtotal
    })
    this.props.updateCartTotal(cartTotal, cartTotalHT, cartTotalVat, cartTotalProducts, cartSubtotal)
  }

  getCartSubtotal = (products) => {
    const orderType = this.props.cart.orderType;
    let cartSubtotalWithoutDiscount = 0
    products.forEach((product) => {
      cartSubtotalWithoutDiscount += product.quantity * product.unitPrice
      product.rubric.forEach((section) => {
        section.data.forEach((rubric) => {
          if (rubric.checked && rubric.unitPrice > 0) {
            if (section.allowSameMultiple) {
              cartSubtotalWithoutDiscount += product.quantity * rubric.unitPrice * (rubric.quantity || 1)
            }
            else {
              cartSubtotalWithoutDiscount += product.quantity * rubric.unitPrice
            }
          }
        })
      })
      if (orderType === 1 && product.takeawayDiscount && product.takeawayDiscount.discount) {
        cartSubtotalWithoutDiscount = cartSubtotalWithoutDiscount * (1 - product.takeawayDiscount.discount / 100)
      }
      else if (orderType === 4 && product.deliveryDiscount && product.deliveryDiscount.discount) {
        cartSubtotalWithoutDiscount = cartSubtotalWithoutDiscount * (1 - product.deliveryDiscount.discount / 100)
      }
      else if ((orderType === 2 || orderType === 3) && product.tableDiscount && product.tableDiscount.discount) {
        cartSubtotalWithoutDiscount = cartSubtotalWithoutDiscount * (1 - product.tableDiscount.discount / 100)
      }
    })
    return cartSubtotalWithoutDiscount
  }

  getProductPrice = (product: ProductType, quantity: number, forTotal: boolean, applyDiscount: boolean): { total: number, totalHT: number, totalVat: number, subtotal: number } => {
    const rubric = product.rubric
    const orderType = this.props.cart.orderType;

    if (typeof product === 'undefined') return { total: 0, totalHT: 0, totalVat: 0 }
    let total = quantity * product.unitPrice
    let totalHT = quantity * product.unitPriceHT


    if (forTotal) {
      rubric.forEach((section) => {
        section.data.forEach((rubric) => {
          if (rubric.checked && rubric.unitPrice > 0) {
            if (section.allowSameMultiple) {
              total += quantity * rubric.unitPrice * (rubric.quantity || 1)
              totalHT += quantity * rubric.unitPriceHT * (rubric.quantity || 1)
            }
            else {
              total += quantity * rubric.unitPrice
              totalHT += quantity * rubric.unitPriceHT
            }
          }
        })
      })
    }

    // apply product discount
    if (orderType === 1 && product.takeawayDiscount && product.takeawayDiscount.discount) {
      total = total * (1 - product.takeawayDiscount.discount / 100)
      totalHT = totalHT * (1 - product.takeawayDiscount.discount / 100)
    }
    else if (orderType === 4 && product.deliveryDiscount && product.deliveryDiscount.discount) {
      total = total * (1 - product.deliveryDiscount.discount / 100)
      totalHT = totalHT * (1 - product.deliveryDiscount.discount / 100)
    }
    else if ((orderType === 2 || orderType === 3) && product.tableDiscount && product.tableDiscount.discount) {
      total = total * (1 - product.tableDiscount.discount / 100)
      totalHT = totalHT * (1 - product.tableDiscount.discount / 100)
    }

    let subtotal = total

    // apply cart discount
    if (this.props.cart.discount && applyDiscount) {
      if (
        (orderType === 1 && (!product.takeawayDiscount || (product.takeawayDiscount && product.takeawayDiscount.canDiscount))) ||
        (orderType === 4 && (!product.deliveryDiscount || (product.deliveryDiscount && product.deliveryDiscount.canDiscount))) ||
        ((orderType === 2 || orderType === 3) && (!product.tableDiscount || (product.tableDiscount && product.tableDiscount.canDiscount)))
      ) {
        total = total * (1 - this.props.cart.discount / 100)
        totalHT = totalHT * (1 - this.props.cart.discount / 100)
      }
    }

    // if (typeof product.discount !== 'undefined' && product.discount !== null && !product.discount.isFidelityDiscount) {
    //   total = total * (1 - product.discount.percentage / 100)
    //   totalHT = totalHT * (1 - product.discount.percentage / 100)
    // }

    let totalVat = total - totalHT
    return { total, totalHT, totalVat, subtotal }
  }


  handleQuantityChange = (event) => {
    let newQuantity = parseInt(event.target.value)
    if (newQuantity === this.props.product.quantity) return
    else if (newQuantity === 0) {
      this.deleteProduct()
    }
    else if (newQuantity !== this.props.product.quantity) {
      this.updateProduct(newQuantity)
    }
  }

  render() {
    return (
      <div className="cart-product-item" onClick={this.props.onClick(this.props.index)}>
        <div className="cart-product-row">
          <div className="cart-row">
            <div className="text-number-product">
              {this.props.editable === false && (
                this.props.product.quantity
              )}
              {(this.props.editable === undefined || this.props.editable === true) && (
                <select className="select select-bordered select-sm max-w-xs" value={this.props.product.quantity} onClick={(e) => { e.stopPropagation(); }} onChange={this.handleQuantityChange}>
                  {Array.apply(null, Array(50)).map((o, idx) =>
                    <option key={idx} value={idx}>{idx}</option>
                  )}
                </select>
              )}

            </div>
            <div className="cart-text">{this.props.product.name}</div>
          </div>
          <div className="cart-text">{`${this.props.product.subtotal.toFixed(2)} €`}</div>
        </div>
        {this.props.product.rubric.map((rubric, index) => {
          if (rubric.data.length > 0)
            return (
              <div key={index} className="cart-rubric-list">
                {rubric.data.map((r, index) => {
                  if (r.checked)
                    return (
                      <div className="rubric-text flex justify-between" key={index}>
                        <div className="text-left">
                          {r.name} {r.unitPrice > 0 ? '(' + r.unitPrice.toFixed(2) + '€)' : ''} {r.quantity && 'x' + r.quantity}
                        </div>
                        <div className="text-right">
                          {r.unitPrice > 0 && (this.props.product.quantity * r.unitPrice * (r.quantity || 1)).toFixed(2) + ' €'}
                        </div>
                      </div>
                    );
                  return null
                })}
              </div>
            );
          else return null;
        })}
      </div>
    )
  }
}

// redux
const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    settings: state.settings
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateProduct: (product, idx) => dispatch(updateProduct(product, idx)),
    updateCartTotal: (cartTotal, cartTotalHT, cartTotalVat, cartTotalProducts, cartSubtotal) => dispatch(updateCartTotal(cartTotal, cartTotalHT, cartTotalVat, cartTotalProducts, cartSubtotal)),
    deleteProduct: (idx) => dispatch(deleteProduct(idx))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCartRow);